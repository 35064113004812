import { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  withRouter,
  Route,
  NavLink,
  Link,
} from "react-router-dom";
import Hero from "./components/Hero";
import About from "./components/About";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Page404 from "./components/Page404";
import Resume from "./components/Resume";
import Curriculum from "./components/Curriculum";
import Works from "./components/Works";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const AnimatedSwitch = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition key={location.key} classNames="slide" timeout={900}>
      <Switch location={location}>
        <Route path="/works" render={() => <Works />}></Route>
        <Route path="/resume" render={() => <Resume />}></Route>
        <Route path="/curriculum" render={() => <Curriculum />}></Route>
        <Route path="/chi-sono" render={() => <About />}></Route>
        <Route path="/come-lavoro" render={() => <Work />}></Route>
        <Route path="/" exact render={() => <Hero />}></Route>
        <Route render={() => <Page404 />}></Route>
      </Switch>
    </CSSTransition>
  </TransitionGroup>
));

class App extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      isMenuActive: false,
      currentPage: null,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };
  handleToggle = () => {
    this.setState({ isMenuActive: !this.state.isMenuActive });
  };
  closeMenu = () => {
    this.setState({ isMenuActive: false });
  };
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    const loc = window.location.pathname;
    const classLoc = loc.replace('/', '')

    if (loc === "/resume" || loc === "/works" || loc === "/curriculum" ) {
      return (
        
        <div className={`App ${classLoc} ${this.state.isMenuActive ? "clipped" : ""}` }>
          <div className="main cv">
            <Router>
              <header>
                <div className="header_wrapper">
                  
                    {loc === "/works" ? (
                        <div className="name">
                      <a href="https://antonioli.dev" className="goback">
                        Annalisa Antonioli
                      </a>
                      </div>
                    ): (
                        <div className="name">
                        <h3>Annalisa Antonioli</h3>
                        <h1 className="identity">Front-end & web developer</h1>
                      </div>
                    )}
                  
                  <div className="contacts">
                    <p>
                      <a href="mailto:annalisa@antonioli.dev">
                        annalisa@antonioli.dev
                      </a>
                    </p>
                    <p>
                      <a href="tel: +39 379 209 60 63"> +39 379 209 60 63</a>
                    </p>
                    <p>Torino, IT</p>
                  </div>
                </div>
              </header>

              <div className="the-content">
                <AnimatedSwitch />
              </div>
            </Router>
          </div>
          <Contact show={this.state.show} handleModalOpening={this.hideModal} />
        </div>
      );
    } else {
      return (
        <div className={`App  ${this.state.isMenuActive ? "clipped" : ""}`}>
          <div className="main">
            <Router>
              <header>
                <div className="header_wrapper">
                  <div className="hamburger" onClick={this.handleToggle}>
                    <div
                      className={`stick stick-1  ${
                        this.state.isMenuActive ? "open" : "close"
                      }`}
                    ></div>
                    <div
                      className={`stick stick-2 ${
                        this.state.isMenuActive ? "open" : "close"
                      }`}
                    ></div>
                    <div
                      className={`stick stick-3 ${
                        this.state.isMenuActive ? "open" : "close"
                      }`}
                    ></div>
                  </div>
                  <h1 className="identity">
                    <Link to="/">Annalisa Antonioli</Link>
                  </h1>
                  <nav className={this.state.isMenuActive ? "open" : ""}>
                    <NavLink to="/chi-sono" onClick={this.closeMenu}>
                      chi sono
                    </NavLink>
                    <NavLink to="/come-lavoro" onClick={this.closeMenu}>
                      come lavoro
                    </NavLink>
                    <span onClick={this.showModal}>contatti</span>
                  </nav>
                </div>
              </header>

              <div className="the-content">
                <AnimatedSwitch />
              </div>
              <div id="contatti" className="content_wrapper">
                <div className="section legal">
                  <p className="legal">
                    Copyright© {this.getYear()} | p.iva 11831300014
                  </p>
                </div>
              </div>
            </Router>
          </div>
          <Contact show={this.state.show} handleModalOpening={this.hideModal} />
        </div>
      );
    }
  }
}

export default App;
