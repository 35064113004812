import { Page, View, Document, Text, StyleSheet, Font } from '@react-pdf/renderer';
import Poppins from '../fonts/Poppins-Regular.ttf'
import BioRhyme from '../fonts/BioRhyme-Regular.ttf'
import BioRhymeBold from '../fonts/BioRhyme-Bold.ttf'
Font.register({
    family: "Poppins",
    fonts: [
        {
            src: Poppins,
            fontWeight: 400,
        },
    ]
});
Font.register({
    family: "BioRhyme",
    fonts: [
        {
            src: BioRhyme,
            fontWeight: 400,
        },
        {
            src: BioRhymeBold,
            fontWeight: 700,
        },
    ]
});
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FAFAFA',
        paddingTop: 25,
        paddingBottom: 25,
        display: "flex",
        flexDirection: 'column',
    },
    header: {
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 10,
        padding: 10,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: 'row',
        backgroundColor: "#D2F7D8"
    },
    section: {
        margin: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    title: {
        fontSize: 12,
        lineHeight: 1,
        fontFamily: 'BioRhyme',
        color: "#403fa6",
        marginBottom: 15
    },
    personal: {
        fontSize: 11,
        lineHeight: 1.5,
        fontFamily: 'BioRhyme',
        color: "#403fa6",
    },
    role: {
        fontSize: 9,
        lineHeight: 1.25,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 7,
    },
    text: {
        fontSize: 9,
        lineHeight: 1.125,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 7
    },
    bullet: {
        fontSize: 8.5,
        lineHeight: 1.125,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 2
    },
    date: {
        fontSize: 8,
        lineHeight: 1.125,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 7
    },
    jobTitle: {
        fontSize: 9,
        lineHeight: 1.5,
        fontFamily: 'BioRhyme',
        color: "#4a4860",
        fontWeight: 700
    },
});
const CurriculumPrintEN = () => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.personal}>Annalisa Antonioli</Text>
                    <Text style={styles.role}>Front-end & Web Developer</Text>
                </View>
                <View>
                    <Text style={styles.text}>annalisa@antonioli.dev</Text>
                    <Text style={styles.text}>+39 379 209 60 63</Text>
                    <Text style={styles.text}>Torino, IT</Text>
                </View>
            </View>
            <View style={styles.section}>
                <View>
                    <Text style={styles.title}>.about</Text>
                    <Text style={styles.text}>I have been working as a web and front-end developer for about 6 years, focusing primarily on the creation of websites, web apps, and small e-commerce platforms. I employ various technologies, frameworks, and CMS depending on the requirements and specifications of each project. I conduct my work both in collaboration with some agencies and as a freelance professional, closely engaging with private clients and small businesses.</Text>
                    <Text style={styles.text}>My experience as a freelancer has taught me how to manage and organize time, commitments, and costs, assessing the best approach for each specific project. Working with agencies allows me to tackle larger and more complex projects, providing the opportunity to collaborate and interact with other professionals. This dual experience enables me to work both independently and as part of a team, in diverse environments and with a wide range of individuals. This flexibility allows me to easily adapt to various work situations.
                    </Text>
                    <Text style={styles.text}>Here it is a selection of projects I have worked on: antonioli.dev/works</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.professional experience</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Freelance Front-end and Web Developer</Text>
                    <Text style={styles.date}>2016 - today</Text>
                    <Text style={styles.bullet}>&#x2022; Websites, SPAs, web and mobile apps development mainly with JS, SCSS, html, React and, sometimes, Vue</Text>
                    <Text style={styles.bullet}>&#x2022; WordPress themes development, generally with ACF plugin</Text>
                    <Text style={styles.bullet}>&#x2022; Small e-commerce with WooCommerce or Shopify</Text>
                    <Text style={styles.bullet}>&#x2022; Support and maintenance.</Text>
                    <Text style={styles.bullet}>I often work with other professionals, such as art directors or graphic designers. As freelance I work or I worked with, among others: Piramid (Torino), Pixel Crew (Milano), Iride Comunicazione (Parma)</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Front-end Developer - Contractor</Text>
                    <Text style={styles.date}>2024 - today @ BasicNet S.p.A. (Torino)</Text>
                    <Text style={styles.bullet}>&#x2022; Creation and optimization of new user-facing features using React</Text>
                    <Text style={styles.bullet}>&#x2022; Management of Shopify themes for the stores</Text>
                    <Text style={styles.bullet}>&#x2022; Development of custom apps for Shopify environment, with Remix along with REST API and Graphql</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Front-end Developer - Contractor</Text>
                    <Text style={styles.date}>2019 - 2023 @ Conversa s.r.l. (Torino)</Text>
                    <Text style={styles.bullet}>&#x2022; Front-end development (websites, SPAs)</Text>
                    <Text style={styles.bullet}>&#x2022; Reusable components development</Text>
                    <Text style={styles.bullet}>&#x2022; Maintenance, updating, upgrading of legacy codes and old websites</Text>
                    <Text style={styles.bullet}>&#x2022;Feasibility studies</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Front-end Developer - Consultant</Text>
                    <Text style={styles.date}>2022 @ Neuno (Sydney, AU)</Text>
                    <Text style={styles.bullet}>&#x2022; Maintenance and management of website </Text>
                    <Text style={styles.bullet}>&#x2022; Making of specific landing page for NTFs drops. </Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Front-end Developer - Contractor</Text>
                    <Text style={styles.date}>2019 @ Accenture (Torino)</Text>
                    <Text style={styles.bullet}>&#x2022; Restyling of some of the websites of Stella Antis (former FCA)</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Web Developer and Web Master - Consultant</Text>
                    <Text style={styles.date}>2017 - 2021(Torino) @ OTTOSUNOVE s.r.l.</Text>
                    <Text style={styles.bullet}>&#x2022; Maintenance and management of websites</Text>
                    <Text style={styles.bullet}>&#x2022; WordPress websites development.</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.education</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Communication Studies - Master's Degree</Text>
                    <Text style={styles.date}>2011 - 2013 @ Università degli Studi di Torino</Text>
                    <Text style={styles.bullet}>Academic year 2012-13 has been spent at the Faculty of Audiovisual Communication at the University of Granada
                        (Spain), within the framework of the Erasmus project</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Communication Studies - Bachelor's Degree</Text>
                    <Text style={styles.date}>2008 - 2011 @ Università degli Studi di Torino</Text>
                </View>
            </View>
            <View style={styles.section} wrap={false}>
                <Text style={styles.title}>.skills</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.bullet}>&#x2022; Javascript, React, HTML, CSS, Sass/SCSS, Vue, Twig, PHP;</Text>
                    <Text style={styles.bullet}>&#x2022; Wordpress, Shopify, GitHub Pages, Jekyll, Astro;</Text>
                    <Text style={styles.bullet}>&#x2022; Tailwind, UIKit, Bulma, MUI;</Text>
                    <Text style={styles.bullet}>&#x2022; Figma, XD, Sketch, InVision;</Text>
                    <Text style={styles.bullet}>&#x2022; Gitlab, GitHub, BitBucket.</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.languages</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.bullet}>&#x2022; Italian - native</Text>
                    <Text style={styles.bullet}>&#x2022; English - B2</Text>
                    <Text style={styles.bullet}>&#x2022; Spanish  B2</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.training courses</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.bullet}>&#x2022; Graphic Design - 2019 @ Forte Chance Piemonte</Text>
                    <Text style={styles.bullet}>&#x2022; Web Development - 2016 @ Delpho Didattica Informatica</Text>
                    <Text style={styles.bullet}>&#x2022; Tourism Management (1st Level Master) - 2014/2016 @ Universidad Politécnica de Cartagena(Murcia - ES) </Text>
                </View>
            </View>
            <Text style={{ textAlign: "center", fontSize: 7, fontFamily: "Poppins", marginTop: 'auto' }}>I hereby authorize the use of my personal data in accordance to the GDPR 679/16 - "European regulation on the protection of personal data".</Text>
        </Page>
    </Document>

)
export default CurriculumPrintEN
