import JSimg from '../img/js.svg'
import Vueimg from '../img/vue.svg'
import ReactImg from '../img/react.svg'
import htmlimg from '../img/html.svg'
import cssimg from '../img/css.svg'
import sassimg from '../img/sass.svg'
import gitimg from '../img/git.svg'
import gif from '../img/liam.gif'
import { PDFDownloadLink } from '@react-pdf/renderer';
import CurriculumPrintEN from './CurriculumPrintEN'

const CV = () => (
    <div className="content_wrapper boxed">
        <div className="section">
            <h2>.about</h2>
            <p>I have been working as a web and front-end developer for about 6 years, focusing primarily on the creation of websites, web apps, and small e-commerce platforms. I employ various technologies, frameworks, and CMS depending on the requirements and specifications of each project. I conduct my work both in collaboration with some agencies and as a freelance professional, closely engaging with private clients and small businesses.</p>
            <p>My experience as a freelancer has taught me how to manage and organize time, commitments, and costs, assessing the best approach for each specific project. Working with agencies allows me to tackle larger and more complex projects, providing the opportunity to collaborate and interact with other professionals. This dual experience enables me to work both independently and as part of a team, in diverse environments and with a wide range of individuals. This flexibility allows me to easily adapt to various work situations.
                <br /> Here it is a selection of projects I have worked on: <a className='underlined' target="_blank" rel="noreferrer" href="https://antonioli.dev/works">antonioli.dev/works</a></p>
        </div>
        <div className="section">
            <h2>.professional experience</h2>
            <div className="flex">
                <div className="data">
                    2016 - today
                </div>
                <div className="content">
                    <h4>Freelance Front-end and Web Developer</h4>
                    <ul>
                        <li>Websites, SPAs, web and mobile apps development mainly with JS, SCSS, html, React and, sometimes, Vue</li>
                        <li>WordPress themes development, generally with ACF plugin</li>
                        <li>Small e-commerce with WooCommerce or Shopify</li>
                        <li>Support and maintenance.</li>
                    </ul>
                    <p>I often work with other professionals, such as art directors or graphic designers.</p>
                    <p>As freelance I work or I worked with, among others: <a className="underlined" href='https://piramid.studio/' target="_blank" rel="noreferrer">Piramid (Torino)</a>, <a className="underlined" href="https://www.pixelcrew.it/" target="_blank" rel="noreferrer">Pixel Crew</a>, <a className="underlined" href="https://iride.digital/" target="_blank" rel="noreferrer">Iride Comunicazione (Parma)</a></p>
                </div>
            </div>
            <div className="flex">
                <div className="data">
                    2024 - today<br />
                    <b>BasicNet S.p.A.</b>
                </div>
                <div className="content">
                    <h4>Front-end Developer - Contractor</h4>
                    <ul>
                        <li>Creation and optimization of new user-facing features using React</li>
                        <li>Management of Shopify themes for the stores</li>
                        <li>Development of custom apps for Shopify environment, with Remix along with REST API and Graphql</li>
                    </ul>
                </div>
            </div>
            <div className="flex">
                <div className="data">
                    2019 - 2023<br />
                    <b>Conversa</b>
                </div>
                <div className="content">
                    <h4>Front-end Developer - Contractor</h4>
                    <ul>
                        <li>Front-end development (websites, SPAs)</li>
                        <li>Reusable components development</li>
                        <li>Maintenance, updating, upgrading of legacy codes and old websites</li>
                        <li>Feasibility studies.</li>
                    </ul>
                </div>
            </div>
            <div className="flex">
                <div className="data">
                    2022<br />
                    <b>Neuno<br />(Sydney, AU)</b>
                </div>
                <div className="content">
                    <h4>Front-end Developer - Consultant</h4>
                    <ul>
                        <li>Maintenance and management of website;</li>
                        <li>Making of specific landing page for NTFs drops.</li>
                    </ul>

                </div>
            </div>
            <div className="flex">
                <div className="data">
                    2019<br />
                    <b>Accenture</b>
                </div>
                <div className="content">
                    <h4>Front-end Developer - Contractor</h4>
                    <ul>
                        <li>Restyling of some of the websites of Stella Antis (former FCA)</li>
                    </ul>


                </div>
            </div>
            <div className="flex">
                <div className="data">
                    2017 - 2021<br />
                    <b>OTTOSUNOVE</b>
                </div>
                <div className="content">
                    <h4>Web Developer and Web Master - Consultant</h4>
                    <ul>
                        <li>Maintenance and management of websites</li>
                        <li>WordPress websites development</li>
                    </ul>

                </div>
            </div>
        </div>
        <div className="section">
            <h2>.education</h2>

            <div className="flex">
                <div className="data">
                    2011 - 2013
                </div>
                <div className="content">
                    <h4>Communication Studies - Master's Degree</h4>
                    <p className="smaller">Università degli Studi di Torino (IT)</p>
                </div>
            </div>
            <div className="flex">
                <div className="data">
                    2012 - 2013
                </div>
                <div className="content">
                    <h4>LLP Erasmus - Comunicación audiovisual</h4>
                    <p className="smaller">Universidad de Granada (ES)</p>
                </div>
            </div>
            <div className="flex">
                <div className="data">
                    2008 - 2011
                </div>
                <div className="content">
                    <h4>Communication Studies - Bachelor's Degree</h4>
                    <p className="smaller">Università degli Studi di Torino</p>
                </div>
            </div>


        </div>

        <div className="section">
            <h2>.skills</h2>
            <h4>I frequently use</h4>
            <div className="toolbox">

                <div><img src={JSimg} alt="js icon" />Javascript</div>
                <div><img src={ReactImg} alt="react icon" />React</div>
                <div><img src={htmlimg} alt="html icon" />HTML</div>
                <div><img src={cssimg} alt="css icon" />CSS</div>
                <div><img src={sassimg} alt="sass icon" />Sass/Scss</div>
                <div><img src={Vueimg} alt="vue icon" />Vue</div>
                <div><img src={gitimg} alt="git icon" />GIT</div>
            </div>
            <div className="toolbox smaller">
                <h4>Along with</h4>
                <ul>
                    <li>Tailwind, UIKit, Bulma </li>
                    <li>React MUI</li>
                    <li>Figma, XD, Sketch, InVision</li>
                </ul>
                {/* <div><img src={sketch} alt="sketch icon"/>Sketch</div>
                        <div><img src={invision} alt="invision icon"/>InVision</div>
                        <div><img src={photoshop} alt="photoshop icon"/>Photoshop</div>
                        <div><img src={illustrator} alt="illustrator icon"/>Illustrator</div>
                        <div><img src={xd} alt="xd icon"/>Adobe XD</div> */}

            </div>
            <div className="toolbox smaller">
                <h4>I also use</h4>
                <ul>
                    <li>PHP</li>
                    <li>Twig</li>
                    <li>Wordpress, Shopify, GitHub Pages, Jekyll, Astro</li>
                </ul>
                {/* <div><img src={sketch} alt="sketch icon"/>Sketch</div>
                        <div><img src={invision} alt="invision icon"/>InVision</div>
                        <div><img src={photoshop} alt="photoshop icon"/>Photoshop</div>
                        <div><img src={illustrator} alt="illustrator icon"/>Illustrator</div>
                        <div><img src={xd} alt="xd icon"/>Adobe XD</div> */}

            </div>
        </div>

        <div className="section">
            <h2>.languages</h2>
            <div><b>Italian</b> - native</div>
            <div><b>English</b> - B2</div>
            <div><b>Spanish</b> - B2</div>
        </div>
        <div className="section">
            <h2>.training courses</h2>
            <div>2019 - <b>Graphic Design</b> - Forte Chance Piemonte</div>
            <div>2016 - <b>Web Development</b> - Delpho Didattica Informatica</div>
            <div>2014 -2016 - <b>Tourism Management - 1st Level Master</b> - Universidad Politécnica de Cartagena(Murcia - ES)</div>

        </div>

        <p className="copy">I hereby authorize the use of my personal data in accordance to the GDPR 679/16 - "European regulation on the protection of personal data".</p>

        <div className='button-download'>

            <PDFDownloadLink document={<CurriculumPrintEN />} fileName="annalisa_antonioli_cv.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Loading ...' : <img src={gif} width={160} title='Printable version' alt="Liam print" />

                }
            </PDFDownloadLink>
            <span>Printable version</span>
        </div>
    </div>

)
export default CV
