import TheBubble from "./TheBubble";
import { Link } from "react-router-dom";

const Hero = (link) => (
    <div>
        <TheBubble />
        <div className="hero">
            <h1>front-end e web developer, freelance.</h1>
            <p>
                Sono qui per aiutarti a portare la tua attività online!<br/>
                Realizzo siti web e web app su misura per piccole e medie imprese. <br/>
                Collaboro con agenzie, startup e altri freelance per dare vita alle tue idee.<br/> Mettiamo insieme qualcosa di unico per il tuo progetto: <Link to='come-lavoro'>scopri come</Link>.
                {/* Realizzo siti e web app per piccole e medie imprese.
        Collaboro con agenzie, startup e altri freelance. Ti aiuto a mettere online le tue idee o il tuo progetto, creando prodotti
        interamente personalizzati: */}
                
            </p>
        </div>
    </div>
)
export default Hero