import { Page, View, Document, Text, StyleSheet, Font } from '@react-pdf/renderer';
import Poppins from '../fonts/Poppins-Regular.ttf'
import BioRhyme from '../fonts/BioRhyme-Regular.ttf'
import BioRhymeBold from '../fonts/BioRhyme-Bold.ttf'
Font.register({
    family: "Poppins",
    fonts: [
        {
            src: Poppins,
            fontWeight: 400,
        },
    ]
});
Font.register({
    family: "BioRhyme",
    fonts: [
        {
            src: BioRhyme,
            fontWeight: 400,
        },
        {
            src: BioRhymeBold,
            fontWeight: 700,
        },
    ]
});
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FAFAFA',
        paddingTop: 25,
        paddingBottom: 25,
        display: "flex",
        flexDirection: 'column',
    },
    header: {
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 10,
        padding: 10,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: 'row',
        backgroundColor: "#D2F7D8"
    },
    section: {
        margin: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    title: {
        fontSize: 12,
        lineHeight: 1,
        fontFamily: 'BioRhyme',
        color: "#403fa6",
        marginBottom: 15
    },
    personal: {
        fontSize: 11,
        lineHeight: 1.5,
        fontFamily: 'BioRhyme',
        color: "#403fa6",
    },
    role: {
        fontSize: 9,
        lineHeight: 1.25,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 7,
    },
    text: {
        fontSize: 9,
        lineHeight: 1.125,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 7
    },
    bullet: {
        fontSize: 8.5,
        lineHeight: 1.125,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 2
    },
    date: {
        fontSize: 8,
        lineHeight: 1.125,
        fontFamily: 'Poppins',
        color: "#4a4860",
        marginBottom: 7
    },
    jobTitle: {
        fontSize: 9,
        lineHeight: 1.5,
        fontFamily: 'BioRhyme',
        color: "#4a4860",
        fontWeight: 700
    },
});
const CurriculumPrint = () => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.personal}>Annalisa Antonioli</Text>
                    <Text style={styles.role}>Front-end & Web Developer</Text>
                </View>
                <View>
                    <Text style={styles.text}>annalisa@antonioli.dev</Text>
                    <Text style={styles.text}>+39 379 209 60 63</Text>
                    <Text style={styles.text}>Torino, IT</Text>
                </View>
            </View>
            <View style={styles.section}>
                <View>
                    <Text style={styles.title}>.in breve</Text>
                    <Text style={styles.text}>Lavoro come sviluppatrice web e front-end da circa 6 anni,  occupandomi sopratutto della realizzazione di siti, web app e piccoli e-commerce, con diverse teconologie, framework e CMS, a seconda delle necessità e delle specifiche del singolo progetto.</Text>
                    <Text style={styles.text}>Svolgo il mio lavoro sia in collaborazione con alcune agenzie sia come libera professionista al fianco di clienti privati e piccole imprese.
                        Essere freelance mi ha insegnato a gestire e organizzare tempo, impegni e costi, oltre a valutare di volta in volta l'approccio migliore al progetto specifico. Il lavoro in agenzia mi permette di mettere mano a progetti
                        più grandi e complessi, portandomi alla collaborazione e al confronto con gli altri.
                        Questa dualità mi permette di lavorare sia in completa autonomia che come parte di un gruppo, in ambienti diversi e con una grande varietà di persone, rendendomi flessibile e capace di adattarmi facilmente alla situazione lavorativa in cui mi trovo.
                    </Text>
                    <Text style={styles.text}>Qui una selezione di progetti a cui ho lavorato: antonioli.dev/works</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.esperienza professionale</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Sviluppatrice front-end e web - Freelance</Text>
                    <Text style={styles.date}>2016 - ad oggi</Text>
                    <Text style={styles.bullet}>&#x2022; Realizzazione di siti, web e desktop app con JS, SCSS, html, React e, a volte, Vue;</Text>
                    <Text style={styles.bullet}>&#x2022; sviluppo di temi WordPress, quasi sempre col supporto del plugin ACF, spesso con Twig (e Timber) oppure headless;</Text>
                    <Text style={styles.bullet}>&#x2022; creazione di piccoli e-commerce con WooCommerce o Shopify;</Text>
                    <Text style={styles.bullet}>&#x2022; supporto e manutenzione.</Text>
                    <Text style={styles.bullet}>Spesso collaboro con altri professionisti e con agenzie. Tra gli altri: Piramid (Torino), Iride Comunicazione (Parma), Purple Network e Pixel Crew.</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Sviluppatrice web e front-end - Collaboratrice</Text>
                    <Text style={styles.date}>2019 - 2023 @ Conversa s.r.l. (Torino)</Text>
                    <Text style={styles.bullet}>&#x2022; Sviluppo front-end di siti e web app;</Text>
                    <Text style={styles.bullet}>&#x2022; realizzazione di componenti riutilizzabili;</Text>
                    <Text style={styles.bullet}>&#x2022; manutenzione, miglioramento e modifiche dei vecchi siti;</Text>
                    <Text style={styles.bullet}>&#x2022; analisi di fattibilità dei progetti;</Text>
                    <Text style={styles.bullet}>&#x2022;valutazione delle tecnologie da utilizzare per nuovi progetti.</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Sviluppatrice front-end - Consulente</Text>
                    <Text style={styles.date}>2019 @ Accenture (Torino)</Text>
                    <Text style={styles.bullet}>&#x2022; restyling di alcuni dei siti di Stella Antis, all'epoca FCA. utilizzando CSS, JS all'interno di AEM </Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Consulente e sviluppatrice Web</Text>
                    <Text style={styles.date}>2017 - 2021(Torino) @ OTTOSUNOVE s.r.l.</Text>
                    <Text style={styles.bullet}>&#x2022; Gestione e mantenimento dei vecchi siti;</Text>
                    <Text style={styles.bullet}>&#x2022; sviluppo di siti in WordPress.</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.formazione</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Laurea Specialistica in Comunicazione e Culture dei Media</Text>
                    <Text style={styles.date}>2011 - 2013 @ Università degli Studi di Torino</Text>
                    <Text style={styles.bullet}>Di cui in un anno nell'ambito del progetto Erasmus presso la facoltà di Comunicación audiovisual all'università di Granada (Spagna)</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Laure in Scienze della Comunicazione</Text>
                    <Text style={styles.date}>2008 - 2011 @ Università degli Studi di Torino</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.jobTitle}>Diploma di maturità scientifica</Text>
                    <Text style={styles.date}>2003 - 2008 @ Liceo Scientifico Statale "G.Galilei" - Ciriè(TO)</Text>
                </View>
            </View>
            <View style={styles.section}wrap={false}>
                <Text style={styles.title}>.competenze</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.bullet}>&#x2022; Javascript, React, HTML, CSS, Sass/SCSS, Vue, Twig, PHP;</Text>
                    <Text style={styles.bullet}>&#x2022; Wordpress, Shopify, GitHub Pages, Jekyll, Astro;</Text>
                    <Text style={styles.bullet}>&#x2022; Tailwind, UIKit, Bulma, MUI;</Text>
                    <Text style={styles.bullet}>&#x2022; Figma, XD, Sketch, InVision;</Text>
                    <Text style={styles.bullet}>&#x2022; Gitlab, GitHub, BitBucket.</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.lingue</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.bullet}>&#x2022; Italiano - madrelingua</Text>
                    <Text style={styles.bullet}>&#x2022; Inglese - B2</Text>
                    <Text style={styles.bullet}>&#x2022; Spagnolo  B2</Text>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.title}>.corsi di formazione</Text>
                <View style={{ marginBottom: 10 }}>
                    <Text style={styles.bullet}>&#x2022; Graphic Design - 2019 @ Forte Chance Piemonte</Text>
                    <Text style={styles.bullet}>&#x2022; Web Development - 2016 @ Delpho Didattica Informatica</Text>
                    <Text style={styles.bullet}>&#x2022; Master di I livello in Tourism Management - 2014 -2016 @ Universidad Politécnica de Cartagena(Murcia - ES) </Text>
                </View>
            </View>
            <Text style={{textAlign: "center", fontSize: 7, fontFamily: "Poppins", marginTop: 'auto'}}>Autorizzo il trattamento dei miei dati personali, ai sensi del D.lgs. 196 del 30 giugno 2003</Text>
        </Page>
    </Document>

)
export default CurriculumPrint
