import {Link} from "react-router-dom";
import travolta from '../img/consufedtravolta.gif'
const Page404 = () => (
    <div className="page404">
        <h1>Ooops!</h1>
        <p>Sembra proprio che qui non ci sia nulla di interessante.</p>
        <Link to="/">Riportami alla home</Link>
        <img src={travolta} alt="404 not found"/>
    </div>
);

export default Page404