const Card = (props) => {
  return (
    <div className="card">
    <div>
    <div className="card__image">
        <img src={`/img/works/${props.image}`} alt={props.title} width="640"/>
      </div>
      <div className="card__content">
      <h4 className='card-item__title'>{props.title}</h4>
        <p className='card-item__type'>{props.type}</p>
        <p className='spec'>{props.descr}<br/>made in {props.date} {props.client !== '' && <>@ {props.client}</>}</p>
        <p className="small">{props.tags}</p>
        {props.links && (
          <div className="card-item__link-container">
            <div>
            {props.links.map((link, index) => (
             link.title ? (
             
              <a key={index} href={link.url} target="_blank" rel="noreferrer">{link.title}</a>
             
             ) : (
              
              <a key={index} href={link.url} target="_blank" rel="noreferrer">Visit website</a>
            
             )
            ))}
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Card;
