import JSimg from '../img/js.svg'
import Vueimg from '../img/vue.svg'
import ReactImg from '../img/react.svg'
import htmlimg from '../img/html.svg'
import cssimg from '../img/css.svg'
import sassimg from '../img/sass.svg'
import gitimg from '../img/git.svg'

import photoshop from '../img/photoshop.svg'
import illustrator from '../img/illustrator.svg'
import sketch from '../img/sketch.svg'
import invision from '../img/invision.svg'
import xd from '../img/xd.svg'


const About = () => (
    <div className="content_wrapper">
        <div className="section">
            <h1>.chi sono</h1>
            <p>Da circa 5 anni realizzo siti, web app, piccoli e-commerce per clienti privati, PMI, e
                in collaborazione con agenzie e startup. Lavoro soprattutto come front-end developer,
                ma mi occupo anche della creazione di siti WordPress, con temi interamente
                personalizzati.</p>
            <p>La mia base operativa è Torino. Ma possiamo collaborare anche se non siamo vicini di casa! Lavoro
                volentieri da remoto e, se le distanze lo consentono, non ho problemi a spostarmi in caso di
                necessità.</p>
            <p>
                Mi piace pensare allo sviluppo web come ad un'attività artigianale e immagino linguaggi e programmi
                che utilizzo come “ferri del mestiere”. Nella mia cassetta degli attrezzi
                puoi trovare:
            </p>
            <div className="toolbox">
                <div><img src={JSimg} alt="js icon"/>Javascript</div>
                <div><img src={Vueimg} alt="vue icon"/>Vue</div>
                <div><img src={ReactImg} alt="react icon"/>React</div>
                <div><img src={htmlimg} alt="html icon"/>HTML</div>
                <div><img src={cssimg} alt="css icon"/>CSS</div>
                <div><img src={sassimg} alt="sass icon"/>Sass/Scss</div>
                <div><img src={gitimg} alt="git icon"/>GIT</div>
            </div>
            <div className="toolbox smaller">
                <div><img src={photoshop} alt="photoshop icon"/>Photoshop</div>
                <div><img src={illustrator} alt="illustrator icon"/>Illustrator</div>
                <div><img src={sketch} alt="sketch icon"/>Sketch</div>
                <div><img src={invision} alt="invision icon"/>InVision</div>
                <div><img src={xd} alt="xd icon"/>Adobe XD</div>

            </div>
        </div>

    </div>
)
export default About
