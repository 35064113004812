import linkedin from '../img/ln.svg'
import github  from '../img/Github.svg'
const Contact = ({handleModalOpening, show}) => {

    const toggleModal = show ? "modal visible" : "modal";

    return (
        <div id="contatti" className={toggleModal}>
            <div className="contact modal-main">
                <span className="close-modal" onClick={handleModalOpening}></span>
                <h1>.contatti</h1>
                <div className="contact-card">
                    <div className="block">
                        <h3>.mail</h3>
                        <a href="mailto:annalisa@antonioli.dev">annalisa@antonioli.dev</a>
                    </div>
                    <div className="block">
                        <h3>.telefono</h3>
                        <a href="tel:+393792096063">+39 379 209 60 63</a>
                    </div>
                </div>
                <div className="social">
                    <a href="https://www.linkedin.com/in/antonioliannalisa/" target="_blank" rel="noreferrer" className="green"><img src={linkedin} alt="linkedin"/></a>
                    <a href="https://github.com/annalisaantonioli/" target="_blank" rel="noreferrer" className="green"><img src={github} alt="github"/></a>
                </div>

            </div>
        </div>
    )


}
export default Contact