
import React, { useState, useEffect } from 'react';
import Card from './card/Card'

const Works = () => {
  const [data, setData] = useState([]);
  const getData = () => {
    fetch('work.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {

        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson.past)
        setData(myJson)
      });
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="content_wrapper">
      <div className="section">
        <h1>Latest works</h1>
        <h3>Some of my most recents works.</h3>
        <div className="portfolio-wrapper">
          {
            data.past && data.past.length > 0 && data.past.map((el, i) =>
              <Card
                key={i}
                image={el.image}
                title={el.title}
                type={el.type}
                descr={el.descr}
                date={el.date}
                client={el.client}
                links={el.links}
                tags={el.tags}
              />
            )
          }
        </div>
      </div>
      {
        data?.current?.length &&
        <div className="section">
          <h1>Currently on</h1>
          <div className="portfolio-wrapper">
            <ul>
              {
                data.current.map((el, i) =>
                  <Card
                    key={i}
                    image={el.image}
                    title={el.title}
                    type={el.type}
                    descr={el.descr}
                    date={el.date}
                    client={el.client}
                    links={el.links}
                    tags={el.tags}
                  />
                )
              }
            </ul>
          </div>
        </div>
      }
    </div>

  );
};

export default Works;
