const Work = () => (
    <div className="content_wrapper">
        <div className="section">
            <h1>.come lavoro</h1>
            <p>
                Mi occupo della realizzazione di siti e web app per privati, liberi professionisti,
                piccole imprese e collaboro con diverse agenzie web come front-end developer.
            </p>
            <p>
                Ogni <strong>sito</strong> web è realizzato <strong>su misura</strong> per le esigenze del cliente e
                rispecchia fedelmente l’obiettivo per cui è pensato.
                I <strong>siti</strong> che realizzo sono <strong>responsive,</strong> ovvero si adattano allo
                schermo del dispositivo con cui vengono visualizzati e sono <strong>strutturati in funzione della
                user-experience</strong>: un sito web dev’essere facilmente navigabile e avere un <strong>layout
                intuitivo</strong> che si adatti a qualsiasi dispositivo mobile.
            </p>
        </div>
        <div className="section">
            <h2>il metodo.</h2>
            <p>La creazione di un sito è un lavoro articolato, che va oltre lo sviluppo e
                richiede competenze trasversali, dalla grafica alla redazione dei testi, passando per
                strategie SEO e principi di comunicazione. Trovare tutte queste competenze in una
                persona sola non è impossibile, ma è certamente difficile. Personalmente credo
                che la divisione dei compiti per area di competenze porti a risultati migliori in
                tempi più rapidi, ed è per questo che collaboro all’occorrenza con grafici, art
                director, fotografi, copywriter, esperti di SEO: il mio obiettivo è garantire un
                risultato ottimale, in cui ogni aspetto è curato nel dettaglio da un professionista
                esperto.</p>
        </div>
        {/*<div className="section">*/}
        {/*    <h2>i passaggi.</h2>*/}
        {/*    <p>Definiti gli obiettivi e l’alberatura del sito, si passa allo sviluppo del layout*/}
        {/*        grafico e, una volta che questo sarà approvato, si prosegue con lo sviluppo vero e*/}
        {/*        proprio, seguito da una fase di test e debug.*/}
        {/*        Solo quando tutto sarà perfettamente funzionante si procederà alla pubblicazione del*/}
        {/*        sito online.</p>*/}
        {/*</div>*/}
    </div>
)
export default Work