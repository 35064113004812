import  { useEffect } from 'react';
import BubbleAnimation from "../js/BubbleAnimation";

function TheBubble () {
    useEffect(() => {
        BubbleAnimation()
    })
    return (
        <div className="svg-container">
            <svg className="scene" width="100%" height="100vh" preserveAspectRatio="none" viewBox="0 0 1440 800"  xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink">
                <path
                    fill=''
                    d="M 1066,436 C 1051,543.8 973.2,656.2 873.6,700.1 756.6,751.7 600.9,725 492.4,657.4 431.5,619.5 387.5,546.9 376.7,476 360.3,368.3 377.9,229.2 462.5,160.5 589.5,57.34 815.4,42.24 952.4,131.7 1044,190.8 1081,328.8 1066,436 Z"
                />
            </svg>
        </div>
    )
}
export default TheBubble